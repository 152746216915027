@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes heart-remove {
  0%, 16.67%, 33.33%, 50%, 66.67%, 83.33% {
    transform: scale(1);
    opacity: 1;
  }
  8.33%, 25%, 41.67%, 58.33%, 75%, 91.67% {
    transform: scale(1);
    opacity: 0;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.heart-remove {
  animation: heart-remove 3s linear forwards;
}
.tooltip {
  visibility: hidden;
  z-index: 10;
  width: 90vw;
  max-width: 300px;
  line-height: 1.6;
  text-align: center;
  direction: rtl;
  background-color: #fff;
  color: #58cc02;
  border: 4px solid #58cc02;
}

@media (min-width: 640px) {
  .tooltip {
    width: 60vw;
  }
}
@media (min-width: 1024px) {
  .tooltip {
    width: 40vw;
  }
}

.progress-bar {
  transition: background 0.3s ease-out;
}